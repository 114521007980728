import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content mt-0 mb-0 fill-height" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "page-content" },
      [
        _c(
          "div",
          { staticClass: "header-content" },
          [
            _c("h2", { staticClass: "date-head" }, [
              _vm._v(" " + _vm._s(_vm.$t("choose_date_header")) + " "),
            ]),
            _c("p", { staticClass: "date-subhead" }, [
              _vm._v(" " + _vm._s(_vm.$t("date_picker_subhead_alpha")) + " "),
            ]),
            _c("p", { staticClass: "step" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("step_count", { current: 6, last: 6 })) +
                  " "
              ),
            ]),
            _c(VProgressLinear, {
              staticClass: "pa-0 mb-0 progress",
              staticStyle: { width: "950px" },
              attrs: {
                color: "#01D181",
                rounded: "",
                height: "6",
                value: (6 * 100) / 6,
              },
            }),
          ],
          1
        ),
        _c(
          "span",
          {
            staticClass: "skip-mobile",
            attrs: { "data-test": "Alpha:Calendly:BtnSkipCalendlyMobile" },
            on: { click: _vm.skipCalendly },
          },
          [_vm._v(_vm._s(_vm.$t("skip_appointment")))]
        ),
        _c(
          VCard,
          { staticClass: "pa-1 ma-0 card-calendly", attrs: { light: "" } },
          [
            _c(
              "span",
              {
                staticClass: "skip-desktop",
                attrs: { "data-test": "Alpha:Calendly:BtnSkipCalendlyDesktop" },
                on: { click: _vm.skipCalendly },
              },
              [_vm._v(_vm._s(_vm.$t("skip_appointment")))]
            ),
            _c(
              VForm,
              {
                ref: "form",
                attrs: { "lazy-validation": _vm.lazy },
                on: {
                  submit: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.checkForm()
                  },
                },
                model: {
                  value: _vm.valid,
                  callback: function ($$v) {
                    _vm.valid = $$v
                  },
                  expression: "valid",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "ma-0 mb-3 mx-n7",
                    staticStyle: { height: "700px" },
                    attrs: { id: "calendly-parent" },
                  },
                  [
                    _c("div", {
                      staticClass: "calendly-inline-widget",
                      attrs: {
                        "data-auto-load": "false",
                        "data-processed": "true",
                      },
                    }),
                  ]
                ),
              ]
            ),
            _vm.alert
              ? _c(
                  VAlert,
                  { staticClass: "ma-2", attrs: { type: "error" } },
                  [_vm._v(" " + _vm._s(_vm.alert) + " ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("img", {
        staticClass: "Dealboard logo",
        attrs: { src: require("@/assets/dealboard-logo-white.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }